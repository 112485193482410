<template>
  <external-page-layout>
    <template>
      <div class="has-text-centered">
        <div class="is-inline-flex has-logo is-clipped box">
          <figure class="image is-centered is-200x200">
            <img :src="eventLogo" v-if="hasEvent && eventLogo" />
            <meeting-application-logo class="svg-icon" v-else></meeting-application-logo>
          </figure>
        </div>

        <template v-if="!emailResent">
          <div class="title mt-2">{{ $t("email_confirmation.token_expired_title") }}</div>
          <div class="subtitle mt-1">{{ $t("email_confirmation.token_expired_description") }}</div>

          <div class="field" ref="emailField">
            <div class="control">
              <input class="input is-simple" type="text" :placeholder="$t('email_confirmation.resend_email')" v-model="email" />
            </div>
            <template v-if="submitted">
              <p v-if="!$v.email.required" class="help is-danger">{{ $t("auth.error_email") }}</p>
              <p v-if="!$v.email.email" class="help is-danger">{{ $t("auth.error_email") }}</p>
              <p v-if="errorMessage" class="help is-danger">{{ errorMessage }}</p>
            </template>
          </div>

          <b-button @click="submit" type="is-primary" class="button mt-1">{{ $t("email_confirmation.resend") }} </b-button>
        </template>
        <template v-else>
          <div class="title mt-2">{{ $t("email_confirmation.register_title") }}</div>
          <div class="subtitle mt-1">{{ $t("email_confirmation.register_description") }}</div>
        </template>
      </div>
    </template>
  </external-page-layout>
</template>

<script>
import ExternalPageLayout from "@/web/components/external/ExternalPageLayout";
import MeetingApplicationLogo from "@/assets/meetingapplication/ma_logo_square.svg";
import { email, required } from "vuelidate/lib/validators";
import RequestMixin from "@/shared/mixins/RequestMixin";
import { mapActions, mapState } from "vuex";
import { pluckErrorCode } from "@/shared/utils";
import Constants from "@/web/constants";

export default {
  name: "EmailConfirmationTokenExpired",
  components: { ExternalPageLayout, MeetingApplicationLogo },
  mixins: [RequestMixin],

  data() {
    return {
      submitted: false,
      email: "",
      emailResent: false,
    };
  },

  validations() {
    return {
      email: { required, email },
    };
  },

  requests: {
    async resend() {
      await this.resendEmailConfirmation({ email: this.email, eventId: this.event && this.event.id }).then(result => {
        this.emailResent = true;
      });
    },
  },

  computed: {
    ...mapState(["event"]),

    errorMessage() {
      const errorCode = this.error && pluckErrorCode(this.error);
      if (errorCode === Constants.REST_EMAIL_ACCOUNT_ALREADY_CONFIRMED) {
        return this.$t("email_confirmation.resend_error_account_already_confirmed");
      } else if (errorCode === Constants.REST_EMAIL_ACCOUNT_NOT_FOUND) {
        return this.$t("email_confirmation.resend_error_account_not_found");
      } else {
        return null;
      }
    },

    hasEvent() {
      return !this.$route.path.startsWith("/registration");
    },

    eventLogo() {
      return this.event && this.event.logo && this.event.logo.file_thumbnail_750_url;
    },

    lpEnabled() {
      if (this.hasEvent) {
        return this.event && this.event.id !== 1138;
      } else {
        return false;
      }
    },
  },

  methods: {
    ...mapActions("auth", ["resendEmailConfirmation"]),

    submit() {
      this.$v.$touch();
      this.$data.submitted = true;

      if (!this.$v.$invalid) {
        this.resend();
      }
    },
  },

  watch: {
    email: {
      immediate: false,
      handler: function (newValue) {
        this.error = null;
      },
    },
  },
};
</script>

<style scoped>
.input {
  max-width: 420px;
}
</style>
